import React, { useState, useEffect } from "react";
import { Container, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";

// Conversion functions
//const inchesToCm = (inches: number): number => Math.round(inches * 2.54);
const cmToInches = (cm: number): number => Math.round(cm / 2.54);
//const lbsToKg = (lbs: number): number => Math.round(lbs * 0.453592);
const kgToLbs = (kg: number): number => Math.round(kg / 0.453592);

// Define types for the Wetsuit
interface Wetsuit {
  productID: number;
  brandID: number;
  genderID: number;
  countryID: number;
  brandName: string;
  type: string;
  genderName: string;
  heightMin: number;
  heightMax: number;
  weightMin: number;
  weightMax: number;
  chestMin: number;
  chestMax: number;
  waistMin: number;
  waistMax: number;
  sizeName: string;
  countryName: string;
  countryCode: string;
  score: number;
  affiliateLinks: string[];
  affiliateProgramNames: string[];
  affiliateProgramCodes: string[];
  affiliateCountries: string[];
}

const ViewBrands: React.FC = () => {
  const location = useLocation();

  // Create a new URLSearchParams object using the query string from the location
  const queryParams = new URLSearchParams(location.search);

  // Use the get() method to access specific query string parameters
  const brand = queryParams.get("brand") || "All";
  const country = queryParams.get("country") || "All";
  const gender = queryParams.get("gender") || "All";
  const isMetric = queryParams.get("isMetric") || "true";

  const [wetsuitData, setWetsuitData] = useState<Wetsuit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  console.log("isMetrix" + isMetric);

  const isNotXsScreen = window.innerWidth > 600;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const isDevelopment = process.env.NODE_ENV === "development";
        let API_URL: string | undefined = isDevelopment
          ? process.env.REACT_APP_BRANDS_API_DEV_URL
          : process.env.REACT_APP_BRANDS_API_PROD_URL;

        if (!API_URL) {
          API_URL = "https://localhost:5000/brand";
        }

        const url = `${API_URL}?brand=${brand}&country=${country}&gender=${gender}`;

        const APIKEY = process.env.REACT_APP_APIKEY;
        const response = await axios.get(url, {
          headers: {
            "x-api-key": APIKEY,
          },
        });

        setWetsuitData(response.data);
        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch data: ${err}`);
        setLoading(false);
      }
    };

    fetchData();
  }, [brand, country, gender]);

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress size={80} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <DataGrid
      rows={wetsuitData.map((wetsuit, index) => ({
        ...wetsuit,
        id: index,
        heightRange:
          isMetric === "true"
            ? `${wetsuit.heightMin} - ${wetsuit.heightMax} cm`
            : `${cmToInches(wetsuit.heightMin)} - ${cmToInches(
                wetsuit.heightMax
              )} in`,
        weightRange:
          isMetric === "true"
            ? `${wetsuit.weightMin} - ${wetsuit.weightMax} kg`
            : `${kgToLbs(wetsuit.weightMin)} - ${kgToLbs(
                wetsuit.weightMax
              )} lbs`,
        chestRange:
          isMetric === "true"
            ? `${wetsuit.chestMin} - ${wetsuit.chestMax} cm`
            : `${cmToInches(wetsuit.chestMin)} - ${cmToInches(
                wetsuit.chestMax
              )} in`,
        waistRange:
          isMetric === "true"
            ? `${wetsuit.waistMin} - ${wetsuit.waistMax} cm`
            : `${cmToInches(wetsuit.waistMin)} - ${cmToInches(
                wetsuit.waistMax
              )} in`,
      }))}
      columns={[
        {
          field: "brandName",
          headerName: "Brand",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "countryName",
          headerName: "Country",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "genderName",
          headerName: "Gender",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "sizeName",
          headerName: "Size",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "heightRange",
          headerName: "Height Range",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "weightRange",
          headerName: "Weight Range",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "chestRange",
          headerName: "Chest Range",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
        {
          field: "waistRange",
          headerName: "Waist Range",
          headerAlign: "center",
          align: "center",
          flex: isNotXsScreen ? 1 : undefined,
        },
      ]}
      paginationMode="client"
      columnHeaderHeight={35}
      rowHeight={30} // This reduces the row height
      disableRowSelectionOnClick
      sx={{
        "& .MuiDataGrid-cell": { padding: "0px 8px", textAlign: "center" },
        "& .MuiDataGrid-columnHeaders": { padding: "8px 8px" },
      }}
    />
  );
};

export default ViewBrands;
