import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import {
  Slider,
  Button,
  Box,
  Typography,
  Collapse,
  useTheme,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Input,
} from "@mui/material";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesDown,
  faAnglesUp,
  faFemale,
  faMale,
  faPersonHalfDress,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

interface InputFormProps {
  onFormSubmit: (formData: {
    brand: string;
    height: number;
    chest: number;
    waist: number;
    weight: number;
    gender: string;
    country: string;
    isMetric: boolean;
    hasBeenSubmitted: boolean;
  }) => void;
  isSectionCollapsed: boolean; // New prop
  onClick?: () => void; // Added onClick prop
}

const InputForm: React.FC<InputFormProps> = ({
  onFormSubmit,
  isSectionCollapsed: initialIsSectionCollapsed,
}) => {
  const [height, setHeight] = useState<number>(() => {
    const savedHeight = Cookies.get("height");
    return savedHeight ? parseInt(savedHeight, 10) : 166;
  }); // in cm
  const [chest, setChest] = useState<number>(() => {
    const savedChest = Cookies.get("chest");
    return savedChest ? parseInt(savedChest, 10) : 96;
  }); // in cm

  const [waist, setWaist] = useState<number>(() => {
    const savedWaist = Cookies.get("waist");
    return savedWaist ? parseInt(savedWaist, 10) : 76;
  }); // in cm

  const [weight, setWeight] = useState<number>(() => {
    const savedWeight = Cookies.get("weight");
    return savedWeight ? parseInt(savedWeight, 10) : 68;
  }); // in kg

  const [gender, setGender] = useState<string>(() => {
    const savedGender = Cookies.get("gender");
    return savedGender ? savedGender : "na";
  });

  const [country, setCountry] = useState<string>(() => {
    const savedCountry = Cookies.get("country");
    return savedCountry ? savedCountry : "Worldwide";
  }); // State for country

  const [isMetric, setIsMetric] = useState<boolean>(() => {
    const savedIsMetric = Cookies.get("isMetric");
    return savedIsMetric ? savedIsMetric === "true" : true;
  });

  const [isSectionCollapsed, setIsSectionCollapsed] = useState<boolean>(() => {
    const savedIsSectionCollapsed = Cookies.get("isSectionCollapsed");
    return savedIsSectionCollapsed
      ? savedIsSectionCollapsed === "true"
      : initialIsSectionCollapsed;
  });

  const getFlagSrc = (country: string): string => {
    const santitizedCountry = country
      ? country.replace(" ", "").toLowerCase()
      : "";
    try {
      return require(`../flags/${santitizedCountry}.png`);
    } catch (error) {
      return require("../flags/unitedstates.png");
    }
  };

  useEffect(() => {
    Cookies.set("height", height.toString());
    Cookies.set("chest", chest.toString());
    Cookies.set("waist", waist.toString());
    Cookies.set("weight", weight.toString());
    Cookies.set("gender", gender);
    Cookies.set("country", country);
    Cookies.set("isMetric", isMetric.toString());
    Cookies.set("isSectionCollapsed", isSectionCollapsed.toString());
  }, [
    height,
    chest,
    waist,
    weight,
    gender,
    country,
    isMetric,
    isSectionCollapsed,
  ]);

  useEffect(() => {
    const setCountryBasedOnLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await axios.get(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
          );
          const countryName = response.data.countryName;

          if (countryName === "United States of America") {
            setCountry("United States");
            setIsMetric(false);
          } else if (countryName === "Australia") {
            setCountry("Australia");
          } else if (
            countryName ===
            "United Kingdom of Great Britain and Northern Ireland (the)"
          ) {
            setCountry("United Kingdom");
          } else {
            setCountry("Worldwide");
          }
        });
      }
    };

    setCountryBasedOnLocation();
  }, []);

  const convertToImperial = (value: number, unit: "height" | "weight") => {
    if (unit === "height") {
      return Math.round(value * 0.393701); // cm to inches
    } else if (unit === "weight") {
      return Math.round(value * 2.20462); // kg to pounds
    }
    return value;
  };

  const convertToMetric = (value: number, unit: "height" | "weight") => {
    if (unit === "height") {
      return Math.round(value * 2.54); // inches to cm
    } else if (unit === "weight") {
      return Math.round(value * 0.453592); // pounds to kg
    }
    return value;
  };

  const toggleUnitSystem = () => {
    const newMetric = !isMetric;

    // Convert measurements based on the new unit system
    if (newMetric) {
      setHeight(convertToMetric(height, "height"));
      setChest(convertToMetric(chest, "height"));
      setWaist(convertToMetric(waist, "height"));
      setWeight(convertToMetric(weight, "weight"));
    } else {
      setHeight(convertToImperial(height, "height"));
      setChest(convertToImperial(chest, "height"));
      setWaist(convertToImperial(waist, "height"));
      setWeight(convertToImperial(weight, "weight"));
    }

    setIsMetric(newMetric);
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    onFormSubmit({
      brand: "",
      height,
      chest,
      waist,
      weight,
      gender,
      country,
      isMetric,
      hasBeenSubmitted: true,
    });

    const isDevelopment = process.env.NODE_ENV === "development";
    const API_URL: string | undefined = isDevelopment
      ? process.env.REACT_APP_SEARCHES_API_DEV_URL
      : process.env.REACT_APP_SEARCHES_API_PROD_URL;

    const url = `${API_URL}`;

    console.log("Submitting form data to:", url);

    if (isXsScreen) {
      setIsSectionCollapsed(true);
    }
  };

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsSectionCollapsed(isXsScreen);
  }, [isXsScreen]);

  const [alignment, setAlignment] = React.useState(
    isMetric ? "metric" : "imperial"
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    if (
      (newAlignment === "metric" && !isMetric) ||
      (newAlignment === "imperial" && isMetric)
    ) {
      toggleUnitSystem();
    }
  };

  const handleChangeGender = (
    event: React.MouseEvent<HTMLElement>,
    gender: string
  ) => {
    setGender(gender);
  };

  const handleChangeCountry = (
    event: React.MouseEvent<HTMLElement>,
    country: string
  ) => {
    setCountry(country);
  };

  return (
    <>
      {isXsScreen && (
        <>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={{ mb: isSectionCollapsed ? -1 : 1, textAlign: "left" }}
            onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}
          >
            {isSectionCollapsed ? (
              <FontAwesomeIcon
                icon={faAnglesDown}
                size="lg"
                style={{ position: "relative", top: "0px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faAnglesUp}
                size="lg"
                style={{ position: "relative", top: "0px" }}
              />
            )}

            <Box
              sx={{ flexGrow: 1, textAlign: "left", padding: "0px 0 0 5px" }}
            >
              <Typography variant="subtitle2" align="center">
                {isSectionCollapsed
                  ? "Show Your Measurements"
                  : "Hide Your Measurments"}
              </Typography>
            </Box>
          </Button>
        </>
      )}

      <Collapse in={!isSectionCollapsed}>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Unit System"
          sx={{
            "& .MuiToggleButtonGroup-grouped": {
              border: "1px solid rgba(0, 0, 0, .5)", // Add a border to make buttons distinct
              "&.Mui-selected": {
                backgroundColor: "#ccc", // Semi-transparent yellow background when selected
                color: "#000", // White text when selected
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "rgba(255, 255, 255, 1.0)", // Semi-transparent light grey when not selected
                color: "#000", // Black text when not selected
              },
            },
          }}
        >
          <ToggleButton
            value="metric"
            sx={{
              padding: 1,
              margin: 0,
              minWidth: 0,
            }}
          >
            <Typography variant="subtitle2">Metric</Typography>
          </ToggleButton>
          <ToggleButton
            value="imperial"
            sx={{
              padding: 1,
              margin: 0,
              minWidth: 0,
            }}
          >
            <Typography variant="subtitle2">Imperial</Typography>
          </ToggleButton>
        </ToggleButtonGroup>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ maxWidth: 500, margin: "auto" }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid size={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Height ({isMetric ? "cm" : "inches"})
              </Typography>
              <Divider />
            </Grid>
            <Grid size={9} sx={{ pr: 1 }}>
              <Slider
                value={height}
                onChange={(_, newValue) => setHeight(newValue as number)}
                min={isMetric ? 100 : 39}
                max={isMetric ? 220 : 86}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid size={3}>
              <Input
                value={height}
                size="small"
                onChange={(e) => setHeight(Number(e.target.value))}
                inputProps={{
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid size={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Chest ({isMetric ? "cm" : "inches"})
              </Typography>
              <Divider />
            </Grid>
            <Grid size={9} sx={{ pr: 1 }}>
              <Slider
                value={chest}
                onChange={(_, newValue) => setChest(newValue as number)}
                min={isMetric ? 50 : 20}
                max={isMetric ? 150 : 59}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid size={3}>
              <Input
                value={chest}
                size="small"
                onChange={(e) => setChest(Number(e.target.value))}
                inputProps={{
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid size={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Waist ({isMetric ? "cm" : "inches"})
              </Typography>
              <Divider />
            </Grid>
            <Grid size={9} sx={{ pr: 1 }}>
              <Slider
                value={waist}
                onChange={(_, newValue) => setWaist(newValue as number)}
                min={isMetric ? 50 : 16}
                max={isMetric ? 130 : 51}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid size={3}>
              <Input
                value={waist}
                size="small"
                onChange={(e) => setWaist(Number(e.target.value))}
                inputProps={{
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid size={12}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Weight ({isMetric ? "kg" : "lbs"})
              </Typography>
              <Divider />
            </Grid>
            <Grid size={9} sx={{ pr: 1 }}>
              <Slider
                value={weight}
                onChange={(_, newValue) => setWeight(newValue as number)}
                min={isMetric ? 30 : 66}
                max={isMetric ? 122 : 369}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid size={3}>
              <Input
                value={weight}
                size="small"
                onChange={(e) => setWeight(Number(e.target.value))}
                inputProps={{
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="bold"
              sx={{ mt: 2 }}
            ></Typography>
          </Box>
          <ToggleButtonGroup
            fullWidth
            value={gender}
            onChange={handleChangeGender}
            aria-label="Gender"
            sx={{
              "& .MuiToggleButtonGroup-grouped": {
                border: "1px solid rgba(0, 0, 0, 1)", // Add a border to make buttons distinct
                "&.Mui-selected": {
                  backgroundColor: "#ccc", // Semi-transparent yellow background when selected
                  color: "#000", // White text when selected
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "rgba(224, 224, 224, 0.0)", // Semi-transparent light grey when not selected
                  color: "#000", // Black text when not selected
                },
              },
            }}
            exclusive
          >
            <ToggleButton
              value="na"
              sx={{ padding: 2, margin: 0, minWidth: 0 }}
            >
              <Box sx={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={faPersonHalfDress} size="xl" />
                <Typography variant="subtitle2">N/A</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton
              value="women"
              sx={{ padding: 2, margin: 0, minWidth: 0 }}
            >
              <Box sx={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={faFemale} size="xl" />
                <Typography variant="subtitle2">Women</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton
              value="men"
              sx={{ padding: 2, margin: 0, minWidth: 0 }}
            >
              <Box sx={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={faMale} size="xl" />
                <Typography variant="subtitle2">Men</Typography>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="bold"
              sx={{ mt: 2 }}
            ></Typography>
          </Box>
          <ToggleButtonGroup
            fullWidth
            value={country}
            exclusive
            onChange={handleChangeCountry}
            aria-label="Country"
            sx={{
              "& .MuiToggleButtonGroup-grouped": {
                border: "1px solid rgba(0, 0, 0, 1)", // Add a border to make buttons distinct
                "&.Mui-selected": {
                  backgroundColor: "#ccc", // Semi-transparent yellow background when selected
                  color: "#000", // White text when selected
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "rgba(224, 224, 224, 0.0)", // Semi-transparent light grey when not selected
                  color: "#000", // Black text when not selected
                },
              },
            }}
          >
            <ToggleButton value="United States" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={getFlagSrc("unitedstates")}
                  alt={`United States flag`}
                  style={{
                    height: 10,
                    width: "auto",
                  }}
                />
                <Typography variant="subtitle2">US</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="United Kingdom" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={getFlagSrc("unitedkingdom")}
                  alt={`United Kingdom flag`}
                  style={{
                    height: 10,
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography variant="subtitle2">UK</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="Australia" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={getFlagSrc("australia")}
                  alt={`Australia flag`}
                  style={{
                    height: 10,
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography variant="subtitle2">AU</Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="Worldwide" sx={{ padding: 0, margin: 0 }}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={getFlagSrc("worldwide")}
                  alt={`Worldwide flag`}
                  style={{
                    height: 10,
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
                <Typography variant="subtitle2">All</Typography>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid size={6}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
              >
                <Typography variant="subtitle2">Size</Typography>
              </Button>
            </Grid>
            <Grid size={6}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  setHeight(166);
                  setChest(96);
                  setWaist(76);
                  setWeight(68);
                  setGender("na");
                  setCountry("Worldwide");
                  setAlignment("metric");
                }}
              >
                <Typography variant="subtitle2">Reset</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  );
};

export default InputForm;
